import React from "react"
import {
  getComponentStructureData,
  transformComponent,
} from "../components/post/components/util"

const staticTypesToComponentType = {
  Strapi_ComponentPostComponenetsPostImage: "post-componenets.post-image",
  Strapi_ComponentPostComponenetsText: "post-componenets.post-image",
}

export const cmsContentToEditor = content => {
  return content.map(({ __typename, ...data }) => {
    const { componentType } = transformComponent({
      __component: staticTypesToComponentType[__typename],
    })

    return { ...getComponentStructureData(componentType), data }
  })
}
