import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { cmsContentToEditor } from "../../utils/editor"
import ShareButtons from "../../components/post/components/shareButtons"
import PostHeader from "../../components/post/components/postHeader"

const BlogPostTemplate = ({ data }) => {
  const {
    strapi: { post = {} },
  } = data

  const {
    title,
    author,
    created_at,
    content,
    socialPhoto: {
      image: { url: imageUrl },
    },
  } = post

  const components = cmsContentToEditor(content)

  return (
    <Layout name="post">
      <SEO title={title} image={imageUrl} />
      <div className="content">
        <PostHeader
          title={title}
          author={author}
          created_at={created_at}
          edit={false}
        />

        <ShareButtons url={window ? window.location.href : null} />

        {components.map(({ component: CMP, data }) => (
          <CMP {...data} edit={false} />
        ))}
      </div>
      {/*<pre>{JSON.stringify(data, null, "\t")}</pre>*/}
    </Layout>
  )
}
export default BlogPostTemplate

export const query = graphql`
  query($id: ID!) {
    strapi {
      post(id: $id) {
        content {
          ... on Strapi_ComponentPostComponenetsPostImage {
            __typename
            image {
              url
              caption
            }
            title
            description
            creditText
            creditUrl
          }
          ... on Strapi_ComponentPostComponenetsText {
            __typename
            text
          }
        }
        slug
        created_at
        title
        socialPhoto {
          image {
            url
          }
        }
        author {
          id
          displayName
          avatar {
            url
          }
        }
      }
    }
  }
`
